@import "~flatpickr/dist/themes/airbnb.css";

/* This is intended as a series of overrides for the "airbnb" Flatpickr theme */
body {
  .flatpickr-calendar {
    margin-top: 20px;
    margin-left: -10px;
    box-shadow: 2px 0 0 var(--theme-primary), -2px 0 0 var(--theme-primary),
      0 2px 0 var(--theme-primary), 0 -2px 0 var(--theme-primary),
      0 3px 13px RGB(0 0 0 / 8%);

    &:before,
    &:after {
      display: none;
    }
  }

  .flatpickr-months {
    margin-top: 8px;

    * {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  .flatpickr-month {
    font-family: var(--font-family-body);
  }

  .flatpickr-current-month {
    color: var(--theme-primary);

    span.cur-month {
      user-select: none;
      background: none;
      pointer-events: none;
    }

    .numInputWrapper {
      background: none;
      user-select: none;
      pointer-events: none;
      width: 5ch;

      input.cur-year {
        user-select: none;
      }
    }

    input.cur-year {
      font-weight: 700;
    }
  }

  .flatpickr-calendar.animate.open {
    animation: none;
    -webkit-animation: none;
  }

  .flatpickr-weekdays {
    padding-bottom: 4px;
    padding-top: 8px;
    font-size: 16px;
  }

  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    background: inherit !important;
  }

  span.flatpickr-weekday {
    font-family: var(--font-family-body);
    font-weight: 400;
    user-select: none;
  }

  span.flatpickr-day {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-black);
    font-family: var(--font-family-body);
    user-select: none;
  }

  /**
  * Border management - overrides flatpicker nth-child border styles.
  * The uncessary left border on the Sunday days are masked by the .dayContainer element
  */
  .dayContainer {
    overflow: hidden;
    transform: translateX(-0.5px);
    border-radius: 0 0 0 5px; /* matches bottom left popover radius */

    .flatpickr-day {
      border-width: 0 !important;
      border-top: 1px solid #e9e9e9 !important;
      border-left: 1px solid #e9e9e9 !important;
    }
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus,
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay,
  .flatpickr-day.today:hover {
    background-color: transparent;
    border-color: #e9e9e9;
    color: var(--color-black);

    &:before {
      position: absolute;
      content: "\A";
      background-color: RGBA(var(--theme-rgb-primary), var(--alpha-20));
      border-radius: 40px;
      z-index: -1;
      height: 30px;
      width: 30px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .flatpickr-day.selected,
  .flatpickr-day.selected:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange,
  .flatpickr-day.flatpickr-day.endRange,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.flatpickr-day.endRange:hover,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.flatpickr-day.endRange:focus {
    color: white;
    &:before {
      background-color: var(--theme-primary);
    }
  }

  .flatpickr-day.flatpickr-disabled {
    color: var(--font-color-hint) !important;
  }

  .flatpickr-day.flatpickr-disabled:hover:before {
    background-color: transparent;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    top: 6px;

    svg {
      display: none;
    }

    &:hover {
      color: var(--theme-primary);
    }

    &:after {
      position: relative;
      font-family: "icomoon";
      top: -2px;
      font-size: 24px;
      color: var(--theme-primary);
    }
  }

  .flatpickr-months .flatpickr-prev-month:after {
    /* narmi-icon-chevron-left */
    content: "\e940";
    margin-left: 30px;
  }
  .flatpickr-months .flatpickr-next-month:after {
    /* narmi-icon-chevron-right */
    content: "\e941";
    margin-right: 30px;
  }
}
