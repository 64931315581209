// input style shared between variants
.nds-radiobuttons input {
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// base option styles
.nds-radiobuttons-option {
  display: block;
  position: relative;
  margin-bottom: var(--space-s);
  cursor: pointer;
  font-size: var(--font-size-default);
  &:last-child {
    margin-bottom: 0;
  }
}

// normal variant of radiobuttons
.nds-radiobuttons--normal {
  display: inline-block;

  .nds-radiobuttons-option {
    padding-left: 28px;

    .nds-radio {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: var(--color-white);
      border: 1px solid var(--color-lightGrey);
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--theme-primary);
      }
    }

    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      border: 1px solid var(--theme-primary);
    }

    &.nds-radiobuttons-option--error .nds-radio {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked .nds-radio {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }
}

// card style radiobuttons variant
.nds-radiobuttons--card {
  display: block;

  .nds-radiobuttons-option {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-white);
    color: var(--theme-primary);
    font-weight: var(--font-weight-bold) !important;

    .narmi-icon-check {
      display: none;
    }

    &:hover,
    &.nds-radiobuttons-option--focused,
    &.nds-radiobuttons-option--checked {
      border-color: var(--theme-primary);
    }

    &.nds-radiobuttons-option--error {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked {
      background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    }

    &.nds-radiobuttons-option--checked .narmi-icon-check {
      margin-left: var(--space-default);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--space-default);
      height: var(--space-default);
      border-radius: 100%;
      background-color: var(--theme-primary);
      color: var(--color-white);
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-default);
    }
  }
}
