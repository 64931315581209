.nds-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

// Row items grow to fit remaining space in row by default
.nds-row-item {
  flex-basis: 0;
  flex-grow: 1;
  width: auto;
  height: 100%;
  min-height: 0;
  min-width: 0;

  // The shrink variant shrink wraps the row item to content width
  &--shrink {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    width: initial;
  }
}
