.collapsible-card--content-card {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    background-color: var(--color-white);
    border: 1px solid var(--color-lightGrey);
    border-radius: 8px;
  }

  .collapsible-card--content-card.content-card--disabled {
    background-color: var(--bgColor-smokeGrey) !important;
    border: 1px solid var(--color-lightGrey) !important;
    cursor: pointer;
  }
  .collapsible-card--content-card.content-card--error {
    border: 1px solid var(--color-errorDark) !important;
  }
  .collapsible-card--content-card.content-card--hover {
    border: 1px solid var(--theme-primary);
    cursor: pointer;
  }
  .collapsible-card--content-card.content-card--closed {
    height: 87px;
  }
  .collapsible-card--content-card.content-card--closed:hover {
    border: 1px solid var(--theme-primary);
    cursor: pointer;
  }
  .collapsible-card--title-container {
    display: flex;
    justify-content: space-between;
  }
  .collapsible-card--no-user-select {
    user-select: none;
  }
  .collapsible-card--content-card .title {
    color: var(--theme-primary) !important;
  }
  .collapsible-card--content-card .title--disabled {
    color: var(--color-mediumGrey) !important;
  }

  .collapsible-card--title-expanded {
    background-color: rgba(var(--theme-rgb-primary), var(--alpha-5));
  }

  .collapsible-card--content-card .subtitle {
    color: var(--color-grey) !important;
  }
  .collapsible-card--content-card .subtitle--disabled {
    color: var(--color-mediumGrey) !important;
  }

  .collapsible-card--statusText {
    color: var(--color-mediumGrey);
  }
