.nds-typography {
  h1,
  h2,
  h3,
  h4 {
    font-family: var(--font-family-heading);
    color: var(--font-color-heading);
    font-weight: var(--font-weight-bold);

    &::first-letter {
      text-transform: capitalize;
    }
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h1 {
    font-size: var(--font-size-heading1);
  }
  h2 {
    font-size: var(--font-size-heading2);
  }
  h3 {
    font-size: var(--font-size-heading3);

    &.nds-sans {
      font-family: var(--font-family-body);
    }
  }
  h4 {
    font-size: var(--font-size-l);

    &.nds-sans {
      font-family: var(--font-family-body);
    }
  }
  h5 {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
  }

  .nds-subheader {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    color: var(--color-black);
  }

  p {
    /* Default is equivalent to `.nds-p1.nds-lighter` */
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-default);

    &.nds-p1 {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-default);

      &.nds-light {
        font-weight: var(--font-weight-semibold);
      }

      &.nds-lighter {
        font-weight: var(--font-weight-normal);
      }
    }

    &.nds-p2 {
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-semibold);

      &.nds-light {
        font-weight: var(--font-weight-normal);
      }
    }

    &.nds-p3 {
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-semibold);

      &.nds-light {
        font-weight: var(--font-weight-normal);
      }
    }
  }
}
