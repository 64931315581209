.nds-loadingShim {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--bgColor-scrimLight);
  z-index: 2;

  /* ensure enough room for the dots animation when there's no content */
  min-height: 200px;
}

.nds-loadingShim-indicator {
  width: 60px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  /* visually center the dots translating between -80% and 80% */
  transform: translateY(-40%);

  div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--color-mediumGrey);
    transform: translateY(-80%);
    animation: upDown 400ms ease-in-out alternate infinite;

    &:nth-child(1) {
      animation-delay: -266ms;
    }
    &:nth-child(2) {
      animation-delay: -133ms;
    }
  }
}

/**
* the final state of the animated transform begins around 50%
* to make the dots appear to rest before beginning the next animation
*/
@keyframes upDown {
  0% {
    transform: translateY(-80%);
  }
  55% {
    transform: translateY(80%);
  }
  100% {
    transform: translateY(80%);
  }
}
