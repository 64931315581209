.nds-combobox {
  position: relative;
}

.nds-combobox--active .nds-input-box {
  border-color: var(--theme-primary);
}

.nds-combobox-list {
  visibility: hidden;
  border: 1px solid var(--theme-primary);
  border-radius: var(--border-radius-default);
  max-height: 40vh;
  overflow-y: scroll;
  z-index: 4;

  &--bottom {
    border-top: 1px solid var(--border-color-default);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &--top {
    border-bottom: 1px solid var(--border-color-default);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--active {
    visibility: visible;
  }

  &:focus {
    outline: none;
  }
}

.nds-combobox-item,
.nds-combobox-heading {
  min-height: var(--space-xl);
}

.nds-combobox-item {
  cursor: pointer;
  position: relative;
  &:hover,
  &--highlighted {
    background: RGBA(var(--theme-rgb-primary), var(--alpha-5));
  }
  &--hasGutter {
    padding-left: var(--space-xl) !important;
  }
  .narmi-icon-check {
    position: absolute;
    left: var(--space-s);
    top: 50%;
    transform: translateY(-50%);
  }
}

.nds-combobox-category {
  list-style: none;

  .narmi-icon-chevron-up {
    display: none;
  }

  &[open] {
    .narmi-icon-chevron-down {
      display: none;
    }
    .narmi-icon-chevron-up {
      display: block;
    }
  }

  summary {
    outline: none;
    display: flex;
    cursor: pointer;
    position: relative;
    min-height: var(--space-xl);

    &:hover {
      background: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    }

    span {
      align-self: center;
    }
  }

  .nds-category-icon {
    font-size: 20px;
    position: absolute;
    right: var(--space-s);
    top: 50%;
    transform: translate(1px, -50%);
  }
}
