.nds-input-error {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-errorDark);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    margin: -0.1em 0.4em 0 0.2em;
    height: 1.2em;
    width: 1.2em;
  }
}