/**
* Standard background colors
*/
@each $bgColor
  in (blueGrey, cloudGrey, neutralGrey, smokeGrey, snowGrey, "white")
{
  .bgColor--#{$bgColor} {
    background-color: var(--bgColor-#{$bgColor}) !important;
  }
}

/**
* brand/base background colors
*/
@each $color in (moss, pine, cove, azul, pistachio, cactus, sand) {
  .bgColor--#{$color} {
    background-color: var(--color-#{$color}) !important;
  }
}

/**
* system background colors
*/
.bgColor--success {
  background-color: var(--color-successLight) !important;
}
.bgColor--warn {
  background-color: var(--color-warnLight) !important;
}
.bgColor--error {
  background-color: var(--color-errorLight) !important;
}

/**
* Theme background colors
*/
.bgColor--theme--primary {
  background-color: var(--theme-primary);
}
.bgColor--theme--secondary {
  background-color: var(--theme-secondary);
}
.bgColor--theme--tertiary {
  background-color: var(--theme-tertiary);
}

/**
* Overlay scrims
*/
.scrim--light {
  background-color: var(--bgColor-scrimLight) !important;
}
.scrim--dark {
  background-color: var(--bgColor-scrimDark) !important;
}
