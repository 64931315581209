// only apply top spacing to a checkbox container
// that is preceeded by another checkbox container
.nds-checkbox-container ~ .nds-checkbox-container {
  padding-top: var(--space-s);
}

// normal checkboxes git a bit of extra spacing
.nds-checkbox-container--normal ~ .nds-checkbox-container--normal {
  margin-top: var(--space-default);
}

// reset paragraph spacing in markdown labels
.nds-checkbox-label p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.nds-checkbox {
  cursor: pointer;
  font-size: var(--font-size-default);

  input {
    cursor: pointer;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  a:hover {
    text-decoration: underline;
  }
}

.nds-checkbox--normal {
  display: flex;
  width: fit-content;
  position: relative;
  align-items: flex-start;
  gap: var(--space-s);

  .narmi-icon-check {
    height: 18px;
    width: 18px;

    // - prevent this flex item from shrinking to checkmark width
    // - visually align checkbox icon baseline with label baseline
    flex-shrink: 0;
    transform: translateY(1px);

    background-color: white;
    font-weight: bold;
    border-radius: 3px;
    border: 1px solid var(--color-lightGrey);
    color: white;
    font-size: 15px;
    text-align: center;
    line-height: 18px;
    box-sizing: content-box;
    &:after {
      color: red;
    }
    &.error {
      border: 1px solid var(--color-errorDark);
    }
  }

  // hover
  .narmi-icon-check:hover {
    border-color: var(--theme-primary);
  }

  // focused
  &.nds-checkbox--focused .narmi-icon-check {
    border-color: var(--theme-primary);
    outline: 3px solid RGBA(var(--theme-rgb-primary), var(--alpha-10));
  }

  // checked
  &.nds-checkbox--checked {
    .narmi-icon-check {
      background-color: var(--theme-primary);
      border-color: var(--theme-primary);
      &:after {
        display: block;
      }
    }
  }
}

.nds-checkbox--card {
  position: relative;
  display: flex;
  flex-direction: row-reverse; // check should render on the right side
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);
  color: var(--color-primary);
  font-weight: var(--font-weight-bold) !important;

  .nds-checkbox-label {
    flex-grow: 1;
  }

  .narmi-icon-check {
    display: none;
  }

  &:hover,
  &.nds-checkbox--checked,
  &.nds-checkbox--focused {
    border-color: var(--theme-primary);
  }

  &.nds-checkbox--checked {
    background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
  }

  &.nds-checkbox--checked {
    .narmi-icon-check {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--space-default);
      height: var(--space-default);
      border-radius: 100%;
      background-color: var(--theme-primary);
      color: var(--color-white);
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-default);
    }
  }
}
