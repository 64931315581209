.nds-loading-skeleton {
  padding: 8px;

  .nds-line-block {
    border-radius: 8px;
    background-color: var(--bgColor-smokeGrey);
    background-image: linear-gradient(
      90deg,
      var(--bgColor-smokeGrey) 0%,
      var(--bgColor-cloudGrey) 20%,
      var(--bgColor-smokeGrey) 60%,
      var(--bgColor-smokeGrey) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 800px;
    animation: shimmer 1s linear infinite;
    margin: 0 20px 20px 20px;
    height: 22px;
    &:first-of-type {
      margin-top: 20px;
    }
    &.header {
      width: 40%;
    }
    &.small {
      height: 22px;
    }
    &.medium {
      height: 28px;
    }
    &.large {
      height: 40px;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
