.nds-separatorList {
  &--noWrap {
    white-space: nowrap;
  }

  li {
    display: inline-flex;
    align-self: center;
  }

  li[data-separator]::after {
    content: attr(data-separator);
    color: var(--font-color-hint);
    padding: 0 var(--space-xs);
  }
}
