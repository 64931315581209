/**
* font color helpers
*/
@each $color in (heading, primary, secondary, hint) {
  .fontColor--#{$color} {
    color: var(--font-color-#{$color}) !important;
  }
}
.fontColor--default {
  color: var(--font-color-primary) !important;
}

/**
* brand/base text colors
*/
@each $color in (moss, pine, cove, azul, pistachio, cactus, sand, "white") {
  .fontColor--#{$color} {
    color: var(--color-#{$color}) !important;
  }
}

/**
* theme colors
*/
.fontColor--theme--primary {
  color: var(--theme-primary);
}
.fontColor--theme--secondary {
  color: var(--theme-secondary);
}
.fontColor--theme--tertiary {
  color: var(--theme-tertiary);
}

/**
* helpers for setting font color to a standard "system" color
*/
.fontColor--success {
  color: var(--color-successDark) !important;
}
.fontColor--warn {
  color: var(--color-warnDark) !important;
}
.fontColor--error {
  color: var(--color-errorDark) !important;
}

/**
* font size helpers
*/
@each $size in (xxs, xs, s, m, l, heading0, heading1, heading2, heading3) {
  .fontSize--#{$size} {
    font-size: var(--font-size-#{$size}) !important;
  }
}
.fontSize--default {
  font-size: var(--font-size-default) !important;
}

/**
* font family helpers
*/
@each $fam in (heading, body) {
  .fontFamily--#{$fam} {
    font-family: var(--font-family-#{$fam}) !important;
  }
}
.fontFamily--default {
  font-family: var(--font-family-default) !important;
}

/**
* font weight helpers
*/
@each $weight in (thin, normal, semibold, bold) {
  .fontWeight--#{$weight} {
    font-weight: var(--font-weight-#{$weight}) !important;
  }
}
.fontWeight--default {
  font-weight: var(--font-weight-default) !important;
}

/**
* whitespace text flow helpers
*/
.whiteSpace--singleLine {
  white-space: nowrap;
}
.whiteSpace--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
%whiteSpace--truncate--multiLine {
  // uses experimental, but widely supported
  // "box" display mode to set content flow
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@each $clamp in (2, 3, 4) {
  .whiteSpace--truncate--multiLine--#{$clamp} {
    @extend %whiteSpace--truncate--multiLine;
    -webkit-line-clamp: #{$clamp};
  }
}
