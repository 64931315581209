@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200..700&display=swap");

@font-face {
  font-family: "Narmi Matiere";
  src: url("fonts/narmi-matiere-bold-webfont.woff2")
      format("woff2"),
    url("fonts/narmi-matiere-bold-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}
