.nds-contentCard {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  background-color: var(--color-white);
}

.nds-contentCard--elevated {
  box-shadow: var(--elevation-low);
}

.nds-contentCard--bordered {
  border: 1px solid var(--color-lightGrey) !important;
}

.nds-contentCard--interactive {
  border: 1px solid var(--color-lightGrey) !important;
  &:hover,
  &:active,
  &[aria-pressed="true"] {
    border-color: var(--theme-primary) !important;
  }
  &:active,
  &[aria-pressed="true"] {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    }
  }
}
