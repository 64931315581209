.button--reset,
.resetButton {
  border-width: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: inherit;
  line-height: inherit;
  min-height: initial;
  vertical-align: inherit;
  cursor: pointer;
}
