.nds-tabs-tabsList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: hidden;
  scrollbar-width: "none"; // Firefox

  &:webkit-scrollbar {
    display: "none"; // Safari + Chrome
  }

  .nds-tabs--bordered & {
    border-bottom: var(--border-size-s) solid var(--border-color-light);
  }
}

.nds-tabs-tabItem {
  border-bottom: 3px solid transparent;
  &:not(:last-child) {
    margin-right: var(--space-xl);
  }
  &--selected {
    border-color: var(--theme-primary);
  }
}

.nds-tabs-button {
  padding: var(--space-xs) 0 !important;
  color: var(--font-color-primary) !important;
  font-size: var(--font-size-default);
  font-family: var(--font-family-default);
  white-space: nowrap;

  &--selected,
  &:hover {
    color: var(--theme-primary) !important;
  }

  &--selected {
    font-weight: var(--font-weight-semibold);
  }
}

.nds-tabs-arrow {
  padding-top: 6px;
}

.nds-tabs-arrow-enter {
  max-width: 0px;
  opacity: 0;
}

.nds-tabs-arrow-enter-active {
  max-width: 32px;
  opacity: 1;
  transition: max-width 300ms, opacity 300ms;
}

.nds-tabs-arrow-exit {
  max-width: 32px;
  opacity: 1;
}

.nds-tabs-arrow-exit-active {
  max-width: 0;
  opacity: 0;
  transition: max-width 300ms, opacity 300ms;
}

.arrow-reponsive {
  flex: 0 0 32px;
}

.panel-responsive {
  padding-left: 32px;
  padding-right: 32px;
}

.display-flex {
  display: flex;
}
