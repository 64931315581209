.nds-tag {
  padding: 0 var(--space-s);
  border-radius: 16px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-xs);
  height: 23px;
  max-width: 100%;
  .narmi-icon-x {
    cursor: pointer;
    display: inline-block;
    transform: translateY(1px);
  }
}
.nds-tag--dismissible {
  height: 25px;
  padding-right: var(--space-xs);
  color: var(--color-white);
  background: var(--theme-tertiary);
  border: 1px solid var(--theme-tertiary);
}
.nds-tag--outline {
  color: var(--theme-primary);
  font-size: var(--font-size-xxs);
  background: transparent;
  border: 1px solid var(--theme-primary);
}
.nds-tag--subdued {
  color: var(--theme-primary);
  background: rgba(var(--theme-rgb-primary), var(--alpha-10));
}
.nds-tag--subdued-secondary {
  color: var(--theme-secondary);
  background: rgba(var(--theme-rgb-secondary), var(--alpha-10));
}
.nds-tag--success {
  background: var(--color-successLight);
  color: var(--color-successDark);
}
.nds-tag--warn {
  background: rgba(var(--rgb-warnLight), 0.8);
  color: var(--color-warnDark);
}
.nds-tag--error {
  background: var(--color-errorLight);
  color: var(--color-errorDark);
}
