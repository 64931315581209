.list--reset {
  list-style-type: none;

  &,
  & li {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
}
